export const halfOpacityWhite = "rgba(255, 255, 255, 0.5)";
export const halfOpacityBlack = "rgba(0, 0, 0, 0.5)";
export const lightOrange = "rgb(235,137,125)";
export const lightBlue = "rgb(86,183,283)";

export const INITIAL_LOCATION = {
  latitude: 37.7749,
  longitude: -122.4194,
  zoom: 12,
};

export const FILTERS = [
  { type: "water_fountain", name: "Water fountain", color: "cyan" },
  { type: "bottle_filler", name: "Bottle filler", color: "orange" },
  { type: "dog_fountain", name: "Dog fountain", color: "magenta" },
  { type: "jug_filler", name: "Jug filler", color: "navy" },
];

export const drawerBleeding = 85;

export const LA_LOCATION = {
  location: { latitude: 34.0522, longitude: -118.2437 },
  zoom: 10,
};

export const NYC_LOCATION = {
  location: { latitude: 40.7128, longitude: -74.006 },
  zoom: 12,
};

export const SF_LOCATION = {
  location: { latitude: 37.7749, longitude: -122.4194 },
  zoom: 12,
};
