import supabase from "../services/supabaseService";
import { fetchLocationsSuccess } from "../state/reducers/locationsReducer";
import { calculateRadius } from "../utils/mapBoxUtils";

export const fetchLocationsInRadius =
  ({ latitude, longitude, zoom }) =>
  async (dispatch) => {
    try {
      const radius = calculateRadius(zoom);

      const { data } = await supabase.rpc("get_locations_within_radius", {
        lat: latitude,
        lon: longitude,
        radius,
      });

      dispatch(fetchLocationsSuccess(data || []));
    } catch (error) {
      console.error("Error getting data", error);
      throw error;
    }
  };
