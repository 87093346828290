import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  locations: [],
  searchLocation: null,
  selectedLocation: null,
  showList: false,
  showInfo: false,
  showSearch: false,
  loading: false,
  error: null,
  filter: null,
};

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    fetchLocationsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchLocationsSuccess(state, action) {
      state.locations = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchLocationsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    toggleShowList(state) {
      state.showList = !state.showList;
    },
    toggleShowSearch(state) {
      state.showSearch = !state.showSearch;
    },
    setSelectedLocation(state, action) {
      state.selectedLocation = action.payload;
    },
    setSearchLocation(state, action) {
      state.searchLocation = action.payload;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
  },
});

export const {
  fetchLocationsStart,
  fetchLocationsSuccess,
  fetchLocationsFailure,
  toggleShowList,
  toggleShowSearch,
  setSelectedLocation,
  setSearchLocation,
  setFilter,
} = locationsSlice.actions;

export const filteredLocations = createSelector(
  (state) => state.locations.locations,
  (state) => state.locations.filter,
  (locations, filter) =>
    locations?.filter((location) => {
      if (!filter) return true;

      return Boolean(Number(location[filter]));
    }),
);

export default locationsSlice.reducer;
